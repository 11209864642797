@mixin background-base($url: null) {
	background-position: center center;
	background-repeat: no-repeat;
	@if $url {
		background-image: url($url);
	}
	font-size: 0;
}

@mixin background-contain($url) {
	@include background-base($url);
	background-size: contain;
}

@mixin background-cover($url) {
	@include background-base($url);
	background-size: cover;
}

@mixin background-custom-size($url, $size) {
	@include background-base($url);
	background-size: $size;
}

@mixin size-equal($size) {
	width: $size;
	height: $size;
}

@mixin aspect-ratio($width, $height) {
	position: relative;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}

	> .u-aspect-ratio-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
