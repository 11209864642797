.l-margin {
	&--right-basic {
		margin-right: 1rem;
	}

	&--bottom-basic {
		margin-bottom: 1rem;
	}

	&--top-basic {
		margin-top: 1rem;
	}
}
