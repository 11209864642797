@charset "UTF-8";
/**
 * HLAVNÍ BARVY
 */
/**
 * TRANSITIONY
 */
/**
 * BORDERY
 */
/*! sanitize.css v8.0.0 | CC0 License | github.com/csstools/sanitize.css */
/* Document
 * ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 2 */
  line-height: 1.15;
  /* 3 */
  -moz-tab-size: 4;
  /* 4 */
  tab-size: 4;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
 * ========================================================================== */
/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse; }

/* Forms
 * ========================================================================== */
/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Remove the margin in Safari.
 */
button,
input,
select {
  margin: 0; }

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * Show the overflow in Edge and IE.
 */
input {
  overflow: visible; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none; }

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge and IE.
 */
details {
  display: block; }

/*
 * Add the correct styles in Edge, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/**
 * ZÁKLADNÍ NASTAVENÍ ELEMENTŮ
 */
html {
  font: 400 normal normal 100%/1.5 sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.wf-active {
  font-family: Poppins; }

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

p,
h3,
h2 {
  margin: 0.5em 0; }

h1 {
  margin: 0; }

table {
  border-collapse: collapse; }

th,
td {
  padding: 0; }

input,
textarea {
  box-sizing: border-box; }

img {
  height: auto;
  max-width: 100%; }

fieldset {
  border: none;
  outline: none;
  padding: 0;
  margin: 0; }

/**
 * TYPOGRAFIE
 */
@font-face {
  font-display: swap;
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-Bold.woff2) format("woff2"), url(../assets/fonts/Poppins-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-Regular.woff2) format("woff2"), url(../assets/fonts/Poppins-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-display: swap;
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-Light.woff2) format("woff2"), url(../assets/fonts/Poppins-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal; }

h1,
h2 {
  font-weight: 300; }

.o-icon {
  height: auto; }

.l-flex {
  display: flex; }
  .l-flex .l-flex-1 {
    flex: 1; }
  .l-flex .l-flex-2 {
    flex: 2; }
  .l-flex .l-flex-3 {
    flex: 3; }
  .l-flex .l-flex-4 {
    flex: 4; }
  .l-flex .l-flex-5 {
    flex: 5; }
  .l-flex .l-flex-6 {
    flex: 6; }
  .l-flex--row {
    flex-direction: row; }
  .l-flex--col {
    flex-direction: column; }
  .l-flex--s-b {
    justify-content: space-between; }
  .l-flex--start-x {
    justify-content: flex-start; }
  .l-flex--end-x {
    justify-content: flex-end; }
  .l-flex--start-y {
    align-items: flex-start; }
  .l-flex--end-y {
    align-items: flex-end; }
  .l-flex--center-x {
    justify-content: center; }
  .l-flex--center-y {
    align-items: center; }
  .l-flex--wrap {
    flex-wrap: wrap; }

.l-height--viewport-full {
  height: 100vh; }

.l-grid {
  display: grid; }

.l-grid--cols-4 {
  grid-template-columns: repeat(4, minmax(100px, 1fr)); }

.l-margin--right-basic {
  margin-right: 1rem; }

.l-margin--bottom-basic {
  margin-bottom: 1rem; }

.l-margin--top-basic {
  margin-top: 1rem; }

.c-content-holder {
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 3rem 1rem; }
  @media only screen and (max-width: 45em) {
    .c-content-holder {
      padding-bottom: 2rem;
      padding-top: 2rem; } }
  @media only screen and (max-width: 32em) {
    .c-content-holder {
      padding-bottom: 1rem;
      padding-top: 1rem; } }

.c-header-banner {
  background-image: url(../assets/img/hero.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative; }
  .c-header-banner:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 43.75%; }
  .c-header-banner > .u-aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  @media only screen and (max-width: 45em) {
    .c-header-banner {
      background-image: url(../assets/img/hero@x2.jpg); } }
  @media only screen and (max-width: 32em) {
    .c-header-banner {
      background-image: url(../assets/img/hero@x3.jpg); } }
  .c-header-banner__content-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
    @media only screen and (max-width: 67.5em) {
      .c-header-banner__content-holder {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem; } }
    @media only screen and (max-width: 45em) {
      .c-header-banner__content-holder {
        padding-top: 1rem;
        padding-bottom: 1rem; } }
    @media only screen and (max-width: 32em) {
      .c-header-banner__content-holder {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; } }
  .c-header-banner__t6-logo-link {
    opacity: 0.2; }
  .c-header-banner__t6-logo-icon {
    color: black;
    width: 8rem;
    height: 8rem; }
    @media only screen and (max-width: 67.5em) {
      .c-header-banner__t6-logo-icon {
        width: 6rem;
        height: 6rem; } }
    @media only screen and (max-width: 32em) {
      .c-header-banner__t6-logo-icon {
        width: 4rem;
        height: 4rem; } }
  .c-header-banner__scroll-down {
    display: flex;
    justify-content: center; }
  .c-header-banner__scroll-down-link {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .c-header-banner__scroll-down-link:hover .c-header-banner__arrow-down-icon {
      stroke: #b51421;
      pointer-events: none; }
  .c-header-banner__text {
    text-decoration: underline;
    font-weight: 300;
    font-size: 1.375rem;
    color: #3c3c3c; }
    @media only screen and (max-width: 67.5em) {
      .c-header-banner__text {
        font-size: 1.25rem; } }
    @media only screen and (max-width: 45em) {
      .c-header-banner__text {
        font-size: 1.125rem; } }
    @media only screen and (max-width: 32em) {
      .c-header-banner__text {
        font-size: 0.875rem; } }
  .c-header-banner__arrow-down-icon {
    stroke: #d62432;
    width: 3.5rem;
    margin-bottom: 1rem;
    transition: stroke 150ms ease;
    height: 3.5rem; }
    @media only screen and (max-width: 67.5em) {
      .c-header-banner__arrow-down-icon {
        width: 3rem;
        height: 3rem;
        margin-bottom: 0.75rem; } }
    @media only screen and (max-width: 45em) {
      .c-header-banner__arrow-down-icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 0.5rem; } }
    @media only screen and (max-width: 32em) {
      .c-header-banner__arrow-down-icon {
        width: 2rem;
        height: 2rem;
        margin-bottom: 0.25rem; } }

.webp.c-header-banner {
  background-image: url(../assets/img/hero.webp); }
  @media only screen and (max-width: 45em) {
    .webp.c-header-banner {
      background-image: url(../assets/img/hero@x2.webp); } }
  @media only screen and (max-width: 32em) {
    .webp.c-header-banner {
      background-image: url(../assets/img/hero@x3.webp); } }

.c-header-bar__logo-link {
  color: white; }

.c-header-bar__logo-icon {
  width: 8rem;
  height: 2.75rem; }
  @media only screen and (max-width: 67.5em) {
    .c-header-bar__logo-icon {
      width: 6rem; } }
  @media only screen and (max-width: 45em) {
    .c-header-bar__logo-icon {
      height: 2rem; } }
  @media only screen and (max-width: 32em) {
    .c-header-bar__logo-icon {
      width: 4rem;
      height: 1.75rem; } }

.c-header-bar__contact {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 45em) {
    .c-header-bar__contact {
      flex-direction: column;
      align-items: flex-end; } }

.c-header-bar__content-holder {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.c-header-bar__contact-tel-text {
  color: white;
  font-weight: 300;
  margin-right: 0.75rem; }
  @media only screen and (max-width: 45em) {
    .c-header-bar__contact-tel-text {
      font-size: 0.75rem;
      margin-right: 0; } }

.c-header-bar__contact-tel {
  font-weight: 700;
  font-size: 1.25rem;
  color: white;
  text-decoration: none; }
  .c-header-bar__contact-tel:after {
    content: "/";
    margin-left: 0.75rem;
    font-weight: 300; }
    @media only screen and (max-width: 45em) {
      .c-header-bar__contact-tel:after {
        display: none; } }
  @media only screen and (max-width: 67.5em) {
    .c-header-bar__contact-tel {
      font-size: 1.125rem; } }
  @media only screen and (max-width: 45em) {
    .c-header-bar__contact-tel {
      font-size: 1rem; } }
  @media only screen and (max-width: 32em) {
    .c-header-bar__contact-tel {
      font-size: 0.75rem; } }

.c-header-bar__contact-scroll-to {
  color: white;
  font-weight: 300;
  text-decoration: underline;
  font-size: 1.25rem;
  display: flex;
  align-items: center; }
  .c-header-bar__contact-scroll-to:after {
    content: "";
    margin-left: 0.75rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #ffffff transparent transparent transparent; }
    @media only screen and (max-width: 32em) {
      .c-header-bar__contact-scroll-to:after {
        border-width: 4px 4px 0 4px;
        margin-left: 0.25rem; } }
  @media only screen and (max-width: 67.5em) {
    .c-header-bar__contact-scroll-to {
      font-size: 1.125rem; } }
  @media only screen and (max-width: 45em) {
    .c-header-bar__contact-scroll-to {
      font-size: 1rem; } }
  @media only screen and (max-width: 32em) {
    .c-header-bar__contact-scroll-to {
      font-size: 0.75rem; } }

.c-red-block {
  background-color: #d62432;
  border-bottom: 3px solid #b51421; }

.c-main-description__heading {
  text-align: center;
  color: #3c3c3c;
  font-weight: 700;
  font-size: 1.625rem; }
  @media only screen and (max-width: 67.5em) {
    .c-main-description__heading {
      font-size: 1.5rem; } }
  @media only screen and (max-width: 45em) {
    .c-main-description__heading {
      font-size: 1.25rem; } }
  @media only screen and (max-width: 32em) {
    .c-main-description__heading {
      font-size: 1rem; } }

.c-main-description__custom-text-wrapper > p {
  text-align: center;
  color: #3c3c3c;
  font-size: 0.875rem; }
  @media only screen and (max-width: 67.5em) {
    .c-main-description__custom-text-wrapper > p {
      font-size: 0.8125rem; } }
  .c-main-description__custom-text-wrapper > p:not(:last-child) {
    margin-bottom: 2rem; }
    @media only screen and (max-width: 67.5em) {
      .c-main-description__custom-text-wrapper > p:not(:last-child) {
        margin-bottom: 1.5rem; } }
    @media only screen and (max-width: 45em) {
      .c-main-description__custom-text-wrapper > p:not(:last-child) {
        margin-bottom: 1.25rem; } }
    @media only screen and (max-width: 32em) {
      .c-main-description__custom-text-wrapper > p:not(:last-child) {
        margin-bottom: 1rem; } }

.c-main-description__custom-text-wrapper a {
  color: #d62432; }

.c-main-description__text--bold {
  font-weight: 700; }

.c-main-description__text--heading {
  font-weight: 300; }

.c-main-project-blocks {
  position: relative;
  overflow: hidden; }
  .c-main-project-blocks:after {
    content: "";
    background-color: #e8e8e8;
    height: 1px;
    position: absolute;
    bottom: 4.5rem;
    left: 0;
    right: 0;
    z-index: -1; }
    @media only screen and (max-width: 45em) {
      .c-main-project-blocks:after {
        display: none; } }
  .c-main-project-blocks__block {
    width: calc(100% / 3);
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem; }
    @media only screen and (max-width: 45em) {
      .c-main-project-blocks__block {
        width: 50%;
        margin-bottom: 2rem; }
        .c-main-project-blocks__block:not(:last-child) {
          padding-bottom: 2rem; } }
    @media only screen and (max-width: 32em) {
      .c-main-project-blocks__block {
        width: auto;
        max-width: 20rem;
        margin-bottom: 1rem; }
        .c-main-project-blocks__block:not(:last-child) {
          padding-bottom: 1rem; } }
  .c-main-project-blocks__blocks-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 0 -1rem -3rem -1rem; }
    @media only screen and (max-width: 45em) {
      .c-main-project-blocks__blocks-container {
        margin-bottom: -2rem; } }
    @media only screen and (max-width: 32em) {
      .c-main-project-blocks__blocks-container {
        margin-bottom: -1rem; } }
  .c-main-project-blocks__block-header {
    background-color: white;
    position: relative;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    display: flex; }
    .c-main-project-blocks__block-header:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .c-main-project-blocks__block-header > .u-aspect-ratio-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .c-main-project-blocks__block-header--bs-cz {
      background-image: url(../assets/img/batteryshop_cz_preview.jpg); }
    .c-main-project-blocks__block-header--bs-sk {
      background-image: url(../assets/img/batteryshop_sk_preview.jpg); }
    .c-main-project-blocks__block-header--t6 {
      background-image: url(../assets/img/t6power_com_preview.jpg); }
  .c-main-project-blocks__ar-content:before {
    content: "";
    position: absolute;
    left: -100vw;
    right: -100vw;
    height: calc(100% + 3rem);
    top: -3rem;
    bottom: 0;
    background: #f3f3f3;
    z-index: -1; }
    @media only screen and (max-width: 45em) {
      .c-main-project-blocks__ar-content:before {
        height: calc(100% + 2rem);
        top: -2rem; } }
    @media only screen and (max-width: 32em) {
      .c-main-project-blocks__ar-content:before {
        height: calc(100% + 1rem);
        top: -1rem; } }
  .c-main-project-blocks__block-heading {
    position: absolute;
    bottom: 0;
    left: 2rem;
    right: 2rem;
    text-align: center;
    margin: 0;
    padding: 1rem 0;
    font-size: 1.5rem;
    color: #3c3c3c;
    border-bottom: 3px solid #d62432;
    word-break: normal; }
    @media only screen and (max-width: 67.5em) {
      .c-main-project-blocks__block-heading {
        font-size: 1.25rem;
        padding: 0.75rem 0; } }
    @media only screen and (max-width: 45em) {
      .c-main-project-blocks__block-heading {
        font-size: 1.125rem;
        padding: 0.5rem 0; } }
    @media only screen and (max-width: 32em) {
      .c-main-project-blocks__block-heading {
        font-size: 1rem; } }
  .c-main-project-blocks__block-body {
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding: 0 1rem; }
  .c-main-project-blocks__block-footer {
    margin-top: auto;
    display: flex;
    justify-content: center; }
  .c-main-project-blocks__block-text {
    color: #3c3c3c;
    font-size: 0.875rem;
    text-align: center;
    margin: 0; }
    @media only screen and (max-width: 67.5em) {
      .c-main-project-blocks__block-text {
        font-size: 0.8125rem; } }
  .c-main-project-blocks__block-button {
    color: white;
    text-decoration: none;
    padding: 0.75rem 1rem;
    transition: background-color 150ms ease;
    display: block;
    position: relative;
    font-size: 0.875rem; }
    @media only screen and (max-width: 67.5em) {
      .c-main-project-blocks__block-button {
        font-size: 0.8125rem; } }
    @media only screen and (max-width: 45em) {
      .c-main-project-blocks__block-button {
        padding: 0.5rem 0.75rem; } }
    .c-main-project-blocks__block-button:hover {
      background-color: #b51421; }
    .c-main-project-blocks__block-button:before, .c-main-project-blocks__block-button:after {
      content: "";
      background-color: white;
      width: 0.5rem;
      position: absolute;
      top: 0;
      bottom: 0; }
    .c-main-project-blocks__block-button:before {
      left: -0.5rem; }
    .c-main-project-blocks__block-button:after {
      right: -0.5rem; }
  .c-main-project-blocks__block-button-icon {
    width: 1rem;
    color: white;
    margin-left: 0.5rem;
    height: 1rem; }
    @media only screen and (max-width: 45em) {
      .c-main-project-blocks__block-button-icon {
        width: 0.75rem;
        height: 0.75rem; } }

.c-footer-contact-bar {
  background-color: #111111;
  display: flex;
  border-bottom: 1px solid #525252; }
  .c-footer-contact-bar__breadcrumb-wrapper {
    display: flex; }
    @media only screen and (max-width: 67.5em) {
      .c-footer-contact-bar__breadcrumb-wrapper {
        flex-direction: column;
        align-items: center; } }
  .c-footer-contact-bar__breadcrumb-part:not(:last-child):after {
    content: "/";
    color: #d62432;
    margin: 0 0.5rem; }
    @media only screen and (max-width: 67.5em) {
      .c-footer-contact-bar__breadcrumb-part:not(:last-child):after {
        display: none; } }
  .c-footer-contact-bar__text {
    color: white;
    font-weight: 300;
    font-size: 0.875rem; }
    @media only screen and (max-width: 67.5em) {
      .c-footer-contact-bar__text {
        font-size: 0.8125rem; } }
    .c-footer-contact-bar__text--bold {
      font-weight: 700; }
  .c-footer-contact-bar__link {
    text-decoration: none; }

.c-footer-contact-form {
  background-color: #1b1b1b; }
  .c-footer-contact-form__form {
    display: flex; }
    @media only screen and (max-width: 32em) {
      .c-footer-contact-form__form {
        flex-direction: column; } }
  .c-footer-contact-form__text-field {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    height: 3rem; }
    .c-footer-contact-form__text-field:not(:last-child) {
      margin-bottom: 1rem; }
    .c-footer-contact-form__text-field--textarea {
      display: block;
      min-height: 15rem;
      max-height: 15rem; }
  .c-footer-contact-form__recaptcha-wrapper {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 32em) {
      .c-footer-contact-form__recaptcha-wrapper > .g-recaptcha {
        transform: scale(0.8); } }
  .c-footer-contact-form__label, .c-footer-contact-form__input {
    position: absolute;
    font-size: 0.875rem; }
    @media only screen and (max-width: 67.5em) {
      .c-footer-contact-form__label, .c-footer-contact-form__input {
        font-size: 0.8125rem; } }
  .c-footer-contact-form__label {
    color: #e8e8e8;
    font-weight: 300;
    z-index: 1;
    top: 1rem;
    left: 1rem;
    right: 0;
    transition: top 150ms ease, left 150ms ease, font-size 150ms ease;
    pointer-events: none; }
  .c-footer-contact-form__input {
    width: 100%;
    box-sizing: border-box;
    background-color: #313131;
    outline: none;
    border: 1px solid #525252;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: white;
    padding: 1rem; }
    .c-footer-contact-form__input--textarea {
      padding: 1rem;
      min-height: 10rem;
      max-height: 15rem; }
  .c-footer-contact-form__submit {
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    color: white;
    padding: 1rem 0;
    cursor: pointer;
    transition: background-color 150ms ease;
    margin-top: auto;
    font-size: 0.875rem; }
    @media only screen and (max-width: 67.5em) {
      .c-footer-contact-form__submit {
        font-size: 0.8125rem; } }
    @media only screen and (max-width: 32em) {
      .c-footer-contact-form__submit {
        padding: 0.75rem 0; } }
    .c-footer-contact-form__submit:hover {
      background-color: #b51421; }
  @media only screen and (max-width: 32em) {
    .c-footer-contact-form__col {
      padding-top: 1rem; } }
  .c-footer-contact-form__col--left {
    flex: 2;
    margin-right: 2rem; }
    @media only screen and (max-width: 32em) {
      .c-footer-contact-form__col--left {
        margin-right: 0;
        margin-bottom: 1rem; } }
  .c-footer-contact-form__col--right {
    flex: 1;
    display: flex;
    flex-direction: column; }

.c-footer-contact-form__input.is-focused + .c-footer-contact-form__label {
  top: -1.5rem;
  left: 0;
  font-size: 0.75rem; }

.c-footer-map__map-wrapper {
  height: 20rem; }

@keyframes preloader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .c-footer-map__map-wrapper .c-footer-map__map-wrapper {
    background-color: #ebe9e5;
    height: 20rem;
    padding: 0;
    position: relative; }
  .c-footer-map__map-wrapper .c-footer-map__map-wrapper:before {
    animation: preloader 0.75s infinite linear;
    background-color: transparent;
    border-radius: 50%;
    border: 5px solid #d62432;
    border-left-color: transparent;
    content: "";
    display: inline-block;
    height: 50px;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 1; }
  @media only screen and (max-width: 45em) {
    .c-footer-map__map-wrapper {
      height: 75vh; } }

.c-footer-map__map {
  height: 100%;
  width: 100%; }

.c-footer-copyright {
  display: flex; }
  .c-footer-copyright .c-content-holder {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .c-footer-copyright__partner {
    margin-bottom: 3rem; }
    @media only screen and (max-width: 45em) {
      .c-footer-copyright__partner {
        margin-bottom: 2rem; } }
    @media only screen and (max-width: 32em) {
      .c-footer-copyright__partner {
        margin-bottom: 1rem; } }
  .c-footer-copyright__pipe-list {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem; }
    @media only screen and (max-width: 45em) {
      .c-footer-copyright__pipe-list {
        margin-bottom: 2rem; } }
    @media only screen and (max-width: 32em) {
      .c-footer-copyright__pipe-list {
        margin-bottom: 1rem; } }
  .c-footer-copyright__pipe-list-item:not(:last-child):after {
    content: "|";
    margin: 0 0.5rem; }
  .c-footer-copyright__text {
    font-weight: 300;
    font-size: 0.875rem; }
    @media only screen and (max-width: 67.5em) {
      .c-footer-copyright__text {
        font-size: 0.8125rem; } }
    .c-footer-copyright__text--bold {
      font-weight: 700; }
  .c-footer-copyright__link {
    color: inherit;
    text-decoration: none; }
    .c-footer-copyright__link:hover {
      color: #d62432; }
  .c-footer-copyright__button {
    transition: background-color 150ms ease;
    position: relative;
    width: 3rem;
    height: 3rem; }
    .c-footer-copyright__button:hover {
      background-color: #b51421; }
    .c-footer-copyright__button:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: calc(50% - 3px);
      left: calc(50% - 6px);
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent white transparent; }
      @media only screen and (max-width: 32em) {
        .c-footer-copyright__button:after {
          border-width: 0 4px 4px 4px;
          top: calc(50% - 2px);
          left: calc(50% - 4px); } }

.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.u-text-vertical-align {
  display: flex;
  align-items: center; }

.u-disabled {
  cursor: not-allowed;
  opacity: 0.5; }
  .u-disabled > * {
    pointer-events: none; }

.u-hidden {
  display: none; }

.u-overflow-hidden {
  overflow: hidden; }

.u-overflow-visible {
  overflow: visible; }

.u-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%; }
