/**
 * ZÁKLADNÍ NASTAVENÍ ELEMENTŮ
 */
html {
	font: 400 normal normal 100%/1.5 sans-serif;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
}

.wf-active {
	font-family: Poppins;
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

p,
h3,
h2 {
	margin: 0.5em 0;
}

h1 {
	margin: 0;
}

table {
	border-collapse: collapse;
}

th,
td {
	padding: 0;
}

input,
textarea {
	box-sizing: border-box;
}

img {
  height: auto;
	max-width: 100%;
}

fieldset {
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
}
