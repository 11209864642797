@function rem($size) {
	$remSize: $size / 16;
	@return #{$remSize}rem;
}

@function half($value) {
	@return $value / 2;
}

@function px($size) {
	@return #{$size}px;
}

@function em($size) {
	$remSize: $size / 16;
	@return #{$remSize}em;
}

@function makeLightColor($color) {
	$light: rgba($color, 0.25);
	@return $light;
}
