/**
 * HLAVNÍ BARVY
 */
$redLight: #d62432;
$redDark: #b51421;
$greyLightest: #f3f3f3;
$greyLighter: #e8e8e8;
$greyLight: #525252;
$greyDark: #3c3c3c;
$greyDarker: #383838;
$greyDarkest: #313131;
$black: #111111;
$blackLight: #1b1b1b;

/**
 * TRANSITIONY
 */
$transitionEase: 150ms ease;

/**
 * BORDERY
 */
