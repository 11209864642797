.c-main-project-blocks {
	position: relative;
	overflow: hidden;

	&:after {
		content: "";
		background-color: $greyLighter;
		height: 1px;
		position: absolute;
		bottom: 4.5rem;
		left: 0;
		right: 0;
		z-index: -1;

		@media only screen and(max-width: 45em) {
			display: none;
		}
	}

	&__block {
		width: calc(100% / 3);
		padding: 0 1rem;
		display: flex;
		flex-direction: column;
		margin-bottom: $spacingDesktop;

		@media only screen and(max-width: 45em) {
			width: 50%;
			margin-bottom: $spacingTablet;

			&:not(:last-child) {
				padding-bottom: $spacingTablet;
			}
		}

		@media only screen and(max-width: 32em) {
			width: auto;
			max-width: 20rem;
			margin-bottom: $spacingMobile;

			&:not(:last-child) {
				padding-bottom: $spacingMobile;
			}
		}
	}

	&__blocks-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		margin: 0 -1rem (-$spacingDesktop) -1rem;

		@media only screen and(max-width: 45em) {
			margin-bottom: -$spacingTablet;
		}

		@media only screen and(max-width: 32em) {
			margin-bottom: -$spacingMobile;
		}
	}

	&__block-header {
		background-color: white;
		@include aspect-ratio(1, 1);
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 100%;
		display: flex;

		&--bs-cz {
			background-image: url(../assets/img/batteryshop_cz_preview.jpg);
		}

		&--bs-sk {
			background-image: url(../assets/img/batteryshop_sk_preview.jpg);
		}

		&--t6 {
			background-image: url(../assets/img/t6power_com_preview.jpg);
		}
	}

	&__ar-content {
		&:before {
			content: "";
			position: absolute;
			left: -100vw;
			right: -100vw;
			height: calc(100% + #{$spacingDesktop});
			top: -$spacingDesktop;
			bottom: 0;
			background: $greyLightest;
			z-index: -1;

			@media only screen and(max-width: 45em) {
				height: calc(100% + #{$spacingTablet});
				top: -$spacingTablet;
			}

			@media only screen and(max-width: 32em) {
				height: calc(100% + #{$spacingMobile});
				top: -$spacingMobile;
			}
		}
	}

	&__block-heading {
		position: absolute;
		bottom: 0;
		left: 2rem;
		right: 2rem;
		text-align: center;
		margin: 0;
		padding: 1rem 0;
		font-size: rem(24);
		color: $greyDark;
		border-bottom: 3px solid $redLight;
		word-break: normal;

		@media only screen and(max-width: 67.5em) {
			font-size: rem(20);
			padding: 0.75rem 0;
		}

		@media only screen and(max-width: 45em) {
			font-size: rem(18);
			padding: 0.5rem 0;
		}

		@media only screen and(max-width: 32em) {
			font-size: rem(16);
		}
	}

	&__block-body {
		margin-bottom: 1rem;
		margin-top: 2rem;
		padding: 0 1rem;
	}

	&__block-footer {
		margin-top: auto;
		display: flex;
		justify-content: center;
	}

	&__block-text {
		color: $greyDark;
		font-size: rem(14);
		text-align: center;
		margin: 0;

		@media only screen and(max-width: 67.5em) {
			font-size: rem(13);
		}
	}

	&__block-button {
		$whiteSpace: 0.5rem;
		color: white;
		text-decoration: none;
		padding: 0.75rem 1rem;
		transition: background-color $transitionEase;
		display: block;
		position: relative;
		font-size: rem(14);

		@media only screen and(max-width: 67.5em) {
			font-size: rem(13);
		}

		@media only screen and(max-width: 45em) {
			padding: 0.5rem 0.75rem;
		}

		&:hover {
			background-color: $redDark;
		}

		&:before,
		&:after {
			content: "";
			background-color: white;
			width: $whiteSpace;
			position: absolute;
			top: 0;
			bottom: 0;
		}

		&:before {
			left: -$whiteSpace;
		}

		&:after {
			right: -$whiteSpace;
		}
	}

	&__block-button-icon {
		width: 1rem;
		color: white;
		margin-left: 0.5rem;
		height: 1rem;

		@media only screen and(max-width: 45em) {
			width: 0.75rem;
			height: 0.75rem;
		}
	}
}
