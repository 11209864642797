.l-flex {
	display: flex;

	@for $i from 1 through 6 {
		.l-flex-#{$i} {
			flex: #{$i};
		}
	}

	&--row {
		flex-direction: row;
	}

	&--col {
		flex-direction: column;
	}

	&--s-b {
		justify-content: space-between;
	}

	&--start-x {
		justify-content: flex-start;
	}

	&--end-x {
		justify-content: flex-end;
	}

	&--start-y {
		align-items: flex-start;
	}

	&--end-y {
		align-items: flex-end;
	}

	&--center-x {
		justify-content: center;
	}

	&--center-y {
		align-items: center;
	}

	&--wrap {
		flex-wrap: wrap;
	}
}
