.c-footer-contact-bar {
	background-color: $black;
	display: flex;
	border-bottom: 1px solid $greyLight;

	&__breadcrumb-wrapper {
		display: flex;

		@media only screen and(max-width: 67.5em) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__breadcrumb-part {
		&:not(:last-child) {
			&:after {
				content: "/";
				color: $redLight;
				margin: 0 0.5rem;

				@media only screen and(max-width: 67.5em) {
					display: none;
				}
			}
		}
	}

	&__text {
		color: white;
		font-weight: 300;
		font-size: rem(14);

		@media only screen and(max-width: 67.5em) {
			font-size: rem(13);
		}

		&--bold {
			font-weight: 700;
		}
	}

	&__link {
		text-decoration: none;
	}
}
