.c-main-description {
	&__heading {
		text-align: center;
		color: $greyDark;
		font-weight: 700;
		font-size: rem(26);

		@media only screen and(max-width: 67.5em) {
			font-size: rem(24);
		}

		@media only screen and(max-width: 45em) {
			font-size: rem(20);
		}

		@media only screen and(max-width: 32em) {
			font-size: rem(16);
		}
	}

	&__custom-text-wrapper {
		& > p {
			text-align: center;
			color: $greyDark;
			font-size: rem(14);

			@media only screen and(max-width: 67.5em) {
				font-size: rem(13);
			}

			&:not(:last-child) {
				margin-bottom: 2rem;

				@media only screen and(max-width: 67.5em) {
					margin-bottom: 1.5rem;
				}

				@media only screen and(max-width: 45em) {
					margin-bottom: 1.25rem;
				}

				@media only screen and(max-width: 32em) {
					margin-bottom: 1rem;
				}
			}
		}

		a {
			color: $redLight;
		}
	}

	&__text {
		&--bold {
			font-weight: 700;
		}

		&--heading {
			font-weight: 300;
		}
	}
}
