.c-header-bar {
	&__logo-link {
		color: white;
	}

	&__logo-icon {
		width: 8rem;
		height: 2.75rem;

		@media only screen and(max-width: 67.5em) {
			width: 6rem;
		}

		@media only screen and(max-width: 45em) {
			height: 2rem;
		}

		@media only screen and(max-width: 32em) {
			width: 4rem;
			height: 1.75rem;
		}
	}

	&__contact {
		display: flex;
		align-items: center;

		@media only screen and(max-width: 45em) {
			flex-direction: column;
			align-items: flex-end;
		}
	}

	&__content-holder {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__contact-tel-text {
		color: white;
		font-weight: 300;
		margin-right: 0.75rem;

		@media only screen and(max-width: 45em) {
			font-size: rem(12);
			margin-right: 0;
		}
	}

	&__contact-tel {
		font-weight: 700;
		font-size: rem(20);
		color: white;
		text-decoration: none;

		&:after {
			content: "/";
			margin-left: 0.75rem;
			font-weight: 300;

			@media only screen and(max-width: 45em) {
				display: none;
			}
		}

		@media only screen and(max-width: 67.5em) {
			font-size: rem(18);
		}

		@media only screen and(max-width: 45em) {
			font-size: rem(16);
		}

		@media only screen and(max-width: 32em) {
			font-size: rem(12);
		}
	}

	&__contact-scroll-to {
		color: white;
		font-weight: 300;
		text-decoration: underline;
		font-size: rem(20);
		display: flex;
		align-items: center;

		&:after {
			content: "";
			margin-left: 0.75rem;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 6px 0 6px;
			border-color: #ffffff transparent transparent transparent;

			@media only screen and(max-width: 32em) {
				border-width: 4px 4px 0 4px;
				margin-left: 0.25rem;
			}
		}

		@media only screen and(max-width: 67.5em) {
			font-size: rem(18);
		}

		@media only screen and(max-width: 45em) {
			font-size: rem(16);
		}

		@media only screen and(max-width: 32em) {
			font-size: rem(12);
		}
	}
}
