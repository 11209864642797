.c-footer-contact-form {
	background-color: $blackLight;

	&__form {
		display: flex;

		@media only screen and(max-width: 32em) {
			flex-direction: column;
		}
	}

	&__text-field {
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;
		height: 3rem;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		&--textarea {
			display: block;
			min-height: 15rem;
			max-height: 15rem;
		}
	}

	&__recaptcha-wrapper {
		margin-bottom: 1rem;
		display: flex;
		justify-content: center;

		& > .g-recaptcha {
			@media only screen and(max-width: 32em) {
				transform: scale(0.8);
			}
		}
	}

	&__label,
	&__input {
		position: absolute;
		font-size: rem(14);

		@media only screen and(max-width: 67.5em) {
			font-size: rem(13);
		}
	}

	&__label {
		color: $greyLighter;
		font-weight: 300;
		z-index: 1;
		top: 1rem;
		left: 1rem;
		right: 0;
		transition: top $transitionEase, left $transitionEase,
			font-size $transitionEase;
		pointer-events: none;
	}

	&__input {
		width: 100%;
		box-sizing: border-box;
		background-color: $greyDarkest;
		outline: none;
		border: 1px solid $greyLight;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		color: white;
		padding: 1rem;

		&--textarea {
			padding: 1rem;
			min-height: 10rem;
			max-height: 15rem;
		}
	}

	&__submit {
		outline: none;
		border-top: none;
		border-left: none;
		border-right: none;
		color: white;
		padding: 1rem 0;
		cursor: pointer;
		transition: background-color $transitionEase;
		margin-top: auto;
		font-size: rem(14);

		@media only screen and(max-width: 67.5em) {
			font-size: rem(13);
		}

		@media only screen and(max-width: 32em) {
			padding: 0.75rem 0;
		}

		&:hover {
			background-color: $redDark;
		}
	}

	&__col {
		@media only screen and(max-width: 32em) {
			padding-top: 1rem;
		}

		&--left {
			flex: 2;
			margin-right: 2rem;

			@media only screen and(max-width: 32em) {
				margin-right: 0;
				margin-bottom: 1rem;
			}
		}

		&--right {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
	}
}

.c-footer-contact-form__input.is-focused {
	& + .c-footer-contact-form__label {
		top: -1.5rem;
		left: 0;
		font-size: rem(12);
	}
}
