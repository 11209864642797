.c-header-banner {
	background-image: url(../assets/img/hero.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include aspect-ratio(16, 7);

	@media only screen and(max-width: 45em) {
		background-image: url(../assets/img/hero@x2.jpg);
	}

	@media only screen and(max-width: 32em) {
		background-image: url(../assets/img/hero@x3.jpg);
	}

	&__content-holder {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;

		@media only screen and(max-width: 67.5em) {
			padding-top: 1.75rem;
			padding-bottom: 1.75rem;
		}

		@media only screen and(max-width: 45em) {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		@media only screen and(max-width: 32em) {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
	}

	&__t6-logo-link {
		opacity: 0.2;
	}

	&__t6-logo-icon {
		color: black;
		width: 8rem;
		height: 8rem;

		@media only screen and(max-width: 67.5em) {
			width: 6rem;
			height: 6rem;
		}

		@media only screen and(max-width: 32em) {
			width: 4rem;
			height: 4rem;
		}
	}

	&__scroll-down {
		display: flex;
		justify-content: center;
	}

	&__scroll-down-link {
		display: flex;
		flex-direction: column;
		align-items: center;

		&:hover {
			.c-header-banner__arrow-down-icon {
				stroke: $redDark;
				pointer-events: none;
			}
		}
	}

	&__text {
		text-decoration: underline;
		font-weight: 300;
		font-size: rem(22);
		color: $greyDark;

		@media only screen and(max-width: 67.5em) {
			font-size: rem(20);
		}

		@media only screen and(max-width: 45em) {
			font-size: rem(18);
		}

		@media only screen and(max-width: 32em) {
			font-size: rem(14);
		}
	}

	&__arrow-down-icon {
		stroke: $redLight;
		width: 3.5rem;
		margin-bottom: 1rem;
		transition: stroke $transitionEase;
		height: 3.5rem;

		@media only screen and(max-width: 67.5em) {
			width: 3rem;
			height: 3rem;
			margin-bottom: 0.75rem;
		}

		@media only screen and(max-width: 45em) {
			width: 2.5rem;
			height: 2.5rem;
			margin-bottom: 0.5rem;
		}

		@media only screen and(max-width: 32em) {
			width: 2rem;
			height: 2rem;
			margin-bottom: 0.25rem;
		}
	}
}

.webp.c-header-banner {
	background-image: url(../assets/img/hero.webp);

	@media only screen and(max-width: 45em) {
		background-image: url(../assets/img/hero@x2.webp);
	}

	@media only screen and(max-width: 32em) {
		background-image: url(../assets/img/hero@x3.webp);
	}
}
