$spacingMobile: 1rem;
$spacingTablet: 2rem;
$spacingDesktop: 3rem;

.c-content-holder {
	max-width: 62.5rem;
	margin: 0 auto;
	padding: $spacingDesktop 1rem;

	@media only screen and(max-width: 45em) {
		padding-bottom: $spacingTablet;
		padding-top: $spacingTablet;
	}

	@media only screen and(max-width: 32em) {
		padding-bottom: $spacingMobile;
		padding-top: $spacingMobile;
	}
}
