.c-footer-copyright {
	display: flex;

	.c-content-holder {
		display: flex;
		flex-direction: column;
		align-items: center;
  }

  &__partner {
		margin-bottom: $spacingDesktop;

		@media only screen and(max-width: 45em) {
			margin-bottom: $spacingTablet;
		}

		@media only screen and(max-width: 32em) {
			margin-bottom: $spacingMobile;
		}
  }
	&__pipe-list {
		display: flex;
		justify-content: center;
		margin-bottom: $spacingDesktop;

		@media only screen and(max-width: 45em) {
			margin-bottom: $spacingTablet;
		}

		@media only screen and(max-width: 32em) {
			margin-bottom: $spacingMobile;
		}
	}

	&__pipe-list-item {
		&:not(:last-child) {
			&:after {
				content: "|";
				margin: 0 0.5rem;
			}
		}
	}

	&__text {
		font-weight: 300;
		font-size: rem(14);

		@media only screen and(max-width: 67.5em) {
			font-size: rem(13);
		}

		&--bold {
			font-weight: 700;
		}
	}

  &__link {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: #d62432
    }
  }

	&__button {
		$size: 3rem;
		transition: background-color $transitionEase;
		position: relative;
		width: $size;
		height: $size;

		&:hover {
			background-color: $redDark;
		}

		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			top: calc(50% - 3px);
			left: calc(50% - 6px);
			border-style: solid;
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent white transparent;

			@media only screen and(max-width: 32em) {
				border-width: 0 4px 4px 4px;
				top: calc(50% - 2px);
				left: calc(50% - 4px);
			}
		}
	}
}
