.c-footer-map {
	&__map-wrapper {
		height: 20rem;

		@keyframes preloader {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
		.c-footer-map__map-wrapper {
			background-color: #ebe9e5;
			height: 20rem;
			padding: 0;
			position: relative;
		}
		.c-footer-map__map-wrapper:before {
			animation: preloader 0.75s infinite linear;
			background-color: transparent;
			border-radius: 50%;
			border: 5px solid #d62432;
			border-left-color: transparent;
			content: "";
			display: inline-block;
			height: 50px;
			left: 50%;
			margin-left: -30px;
			margin-top: -30px;
			position: absolute;
			top: 50%;
			width: 50px;
			z-index: 1;
		}

		@media only screen and(max-width: 45em) {
			height: 75vh;
		}
	}

	&__map {
		height: 100%;
		width: 100%;
	}
}
